const edgeAdminRole = {
  Id: 0,
  Name: 'Edge administrator',
  Description: 'Full control of all resources in all edge environments and access to the Edge Compute features',
  Priority: 1,
};

/* @ngInject */
export function RoleService(Roles) {
  return {
    roles,
    rolesWithEdgeAdmin,
  };

  async function roles() {
    return Roles.query({}).$promise;
  }

  async function rolesWithEdgeAdmin() {
    const data = await roles();
    // manually put the edge admin role as it is not an RBAC role in the backend
    // but is defined by user.Role = Role.EdgeAdministrator (3)
    data.push(edgeAdminRole);
    return data;
  }
}
