import { CellContext } from '@tanstack/react-table';

import UpdatesAvailable from '@/assets/ico/icon_updates-available.svg?c';
import UpToDate from '@/assets/ico/icon_up-to-date.svg?c';
import UpdatesUnknown from '@/assets/ico/icon_updates-unknown.svg?c';
import { useEnvironmentId } from '@/react/hooks/useEnvironmentId';
import { isRegularStack } from '@/react/docker/stacks/view-models/utils';
import { ImageStatus } from '@/react/docker/components/ImageStatus';
import { useEnvironment } from '@/react/portainer/environments/queries';
import { isBE } from '@/react/portainer/feature-flags/feature-flags.service';

import { Icon } from '@@/Icon';
import { Tooltip } from '@@/Tip/Tooltip';

import { DecoratedStack } from '../types';

import { columnHelper } from './helper';

export const imageNotificationColumn = columnHelper.display({
  id: 'imageNotification',
  enableHiding: false,
  header: () => (
    <>
      Images up to date
      <StackImageUpToDateTooltip />
    </>
  ),
  cell: Cell,
});

function Cell({
  row: { original: item },
}: CellContext<DecoratedStack, unknown>) {
  const environmentId = useEnvironmentId();

  return (
    <ImageStatus
      environmentId={environmentId}
      resourceId={`${item.Id}`}
      isLimitedStack={!isRegularStack(item)}
      resourceType="stack"
    />
  );
}

export function StackImageUpToDateTooltip() {
  const environmentId = useEnvironmentId();

  const enableImageNotificationQuery = useEnvironment(
    environmentId,
    (environment) => environment?.EnableImageNotification
  );

  if (!enableImageNotificationQuery.data) {
    return null;
  }

  if (!isBE) {
    return null;
  }

  return (
    <Tooltip
      position="top"
      message={
        <div className="flex flex-col gap-y-2 p-2">
          <div className="flex items-center gap-2">
            <Icon icon={UpToDate} />
            Images are up to date
          </div>
          <div className="flex items-center gap-2">
            <Icon icon={UpdatesAvailable} />
            Updates are available
          </div>
          <div className="flex items-center gap-2">
            <Icon icon={UpdatesUnknown} />
            Updates availability unknown
          </div>
          <div>
            Indicators are cached for 24 hours but you can click on an indicator
            or use the reload image indicators button above to reload on demand.
          </div>
          <div>
            Note: The image up to date status for limited stacks cannot be
            retrieved.
          </div>
        </div>
      }
    />
  );
}
