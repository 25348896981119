import { Loader2 } from 'lucide-react';
import clsx from 'clsx';

import { useEnvironment } from '@/react/portainer/environments/queries';
import { geStatusIcon } from '@/react/docker/components/ImageStatus/helpers';
import { EnvironmentId } from '@/react/portainer/environments/types';
import { isBE } from '@/react/portainer/feature-flags/feature-flags.service';

import { Icon } from '@@/Icon';
import { TooltipWithChildren } from '@@/Tip/TooltipWithChildren';

import { ResourceID, ResourceType } from './types';
import { useImageStatus } from './queries/useImageStatus';
import { useRefreshImageStatus } from './queries/useRefreshImageStatus';

export interface Props {
  environmentId: EnvironmentId;
  resourceId: ResourceID;
  resourceType?: ResourceType;
  nodeName?: string;
  isLimitedStack?: boolean;
}

export function ImageStatus({
  environmentId,
  resourceId,
  resourceType = 'container',
  nodeName = '',
  isLimitedStack = false,
}: Props) {
  const enableImageNotificationQuery = useEnvironment(
    environmentId,
    (environment) => environment?.EnableImageNotification
  );
  const imageStatusQuery = useImageStatus(
    environmentId,
    resourceId,
    resourceType,
    nodeName,
    enableImageNotificationQuery.data && !isLimitedStack
  );
  const imageStatus = imageStatusQuery.data?.Status;
  const StatusIcon = geStatusIcon(imageStatus);

  const clearImageStatusMutation = useRefreshImageStatus({
    environmentId,
    resourceId,
    resourceType,
    nodeName,
  });

  if (!enableImageNotificationQuery.data) {
    return null;
  }

  if (!isBE) {
    return null;
  }

  if (
    imageStatusQuery.isLoading ||
    imageStatusQuery.isRefetching ||
    clearImageStatusMutation.isLoading
  ) {
    return (
      <Icon
        icon={Loader2}
        size="sm"
        className="!mx-1 animate-spin-slow align-middle"
      />
    );
  }

  if (isLimitedStack) {
    return (
      <TooltipWithChildren message="Can't reload image up to date indicator for a limited stack">
        {/* add div to avoid tippy forward ref errors */}
        <div className="mx-1 w-[14px]">
          <Icon icon={StatusIcon} size="sm" className="align-middle" />
        </div>
      </TooltipWithChildren>
    );
  }

  return (
    <button
      title="Reload image up to date indicator"
      type="button"
      className="!px-1 border-none bg-transparent !ml-0"
      onClick={() => clearImageStatusMutation.mutate()}
    >
      <Icon
        icon={StatusIcon}
        size="sm"
        className={clsx('align-middle', {
          'animate-spin-slow': imageStatus === 'processing',
        })}
      />
    </button>
  );
}
