import { Secret } from 'kubernetes-types/core/v1';

import { useAuthorizations, useCurrentUser } from '@/react/hooks/useUser';
import { useEnvironmentId } from '@/react/hooks/useEnvironmentId';
import { useEnvironment } from '@/react/portainer/environments/queries';
import { UserId } from '@/portainer/users/types';

import { appOwnerIdLabel } from '../applications/constants';

import { configurationOwnerIdLabel } from './constants';

export function useIsSecretRestricted(secret: Secret) {
  const environmentId = useEnvironmentId();
  const { user } = useCurrentUser();
  const restrictSecretsQuery = useEnvironment(
    environmentId,
    (env) => env?.Kubernetes.Configuration.RestrictSecrets
  );
  const isSecretsRestricted = !!restrictSecretsQuery.data;
  const isEnvironmentAdminQuery = useAuthorizations('K8sClusterW');

  return getIsSecretRestricted(
    secret,
    user.Id,
    isEnvironmentAdminQuery.authorized,
    isSecretsRestricted
  );
}

export function getIsSecretRestricted(
  secret: Secret,
  userId: UserId,
  isEnvironmentAdmin: boolean,
  isSecretsRestricted: boolean
) {
  const userOwnsSecret =
    secret?.metadata?.labels?.[configurationOwnerIdLabel] === `${userId}` ||
    // portainer adds the label 'io.portainer.kubernetes.application.owner.id' to all resources created via manifest
    // secrets made via manifest will have this label instead of the 'io.portainer.kubernetes.configuration.owner.id' label
    secret?.metadata?.labels?.[appOwnerIdLabel] === `${userId}`;

  return isSecretsRestricted && !isEnvironmentAdmin && !userOwnsSecret;
}
