const autoNgTemplateLoaderTemplate1 = require('./time-window-picker.html');

import angular from 'angular';
import controller from './time-window-picker.controller';
import './time-window-picker.css';

angular.module('portainer.app').component('timeWindowPicker', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    timeWindow: '=',
    timeZone: '=',
  },
});
