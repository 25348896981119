/**
 * This service has been created to request the docker registry API
 * without triggering AngularJS digest cycles
 * For more information, see https://github.com/portainer/portainer/pull/2648#issuecomment-505644913
 */

import { deleteManifest, getTagManifestV1, getTagManifestV2, updateManifest } from '@/react/portainer/registries/repositories/queries/manifest.service';

angular.module('portainer.registrymanagement').factory('RegistryManifestsJquery', RegistryManifestsJqueryFactory);

/* @ngInject */
function RegistryManifestsJqueryFactory() {
  return {
    get: getTagManifestV1,
    getV2: getTagManifestV2,
    put: updateManifest,
    delete: deleteManifest,
  };
}
