const autoNgTemplateLoaderTemplate1 = require('./template.html');

import angular from 'angular';

import { ConfigureRegistryController } from './controller';

angular.module('portainer.registrymanagement').component('configureRegistryView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: ConfigureRegistryController,
});
