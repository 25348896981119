import { KubernetesConfigurationKinds } from 'Kubernetes/models/configuration/models';

export default class {
  $onInit() {
    const secrets = (this.configurations || []).filter((config) => config.Data && config.Kind === KubernetesConfigurationKinds.SECRET);

    this.canSeeSecretDetails = function (item) {
      return !this.isSecretsRestricted || this.isEnvironmentAdmin || item.ConfigurationOwner === this.username;
    };

    const secretsEntries = secrets.flatMap((secret) => {
      const secretEntry = Object.entries(secret.Data).map(([key, value]) => ({
        key,
        value,
        // for each entry, check if the user can see the secret details based on the secret owner, user role, and if secrets are restricted
        canSeeSecretDetails: this.canSeeSecretDetails(secret),
      }));
      return secretEntry;
    });

    this.state = { secrets: secretsEntries };
  }
}
