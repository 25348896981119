import { useQueries } from 'react-query';

import { EnvironmentId } from '@/react/portainer/environments/types';
import { withError } from '@/react-tools/react-query';

import { getResourceQuotas } from './useResourceQuotasQuery';

export function useResourceQuotasQueries(
  environmentId: EnvironmentId,
  namespaces: string[] = []
) {
  return useQueries(
    namespaces.map((namespace) => ({
      queryKey: [
        'environments',
        environmentId,
        'kubernetes',
        'resource_quota',
        namespace,
      ],
      queryFn: () => getResourceQuotas(environmentId, namespace),
      ...withError(
        `Unable to get resource quotas for the namespace ${namespace}.`
      ),
    })) ?? []
  );
}
