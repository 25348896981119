import { Loader2 } from 'lucide-react';

import UpdatesAvailable from '@/assets/ico/icon_updates-available.svg?c';
import UpToDate from '@/assets/ico/icon_up-to-date.svg?c';
import UpdatesUnknown from '@/assets/ico/icon_updates-unknown.svg?c';

import { Status } from './types';

export function geStatusIcon(status?: Status) {
  switch (status) {
    case 'outdated':
      return UpdatesAvailable;
    case 'updated':
      return UpToDate;
    case 'processing':
      return Loader2;
    case 'error': // may want a different icon for this in future, along with an error message
    case 'skipped':
    default:
      return UpdatesUnknown;
  }
}
