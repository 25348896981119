import angular from 'angular';

import { AccessHeaders } from '../authorization-guard';
import { LicenseService } from './license.service';
import licensesViewModule from './licenses.view';

export default angular.module('portainer.app.license-management', [licensesViewModule]).config(config).service('LicenseService', LicenseService).name;

/* @ngInject */
function config($stateRegistryProvider) {
  const licenses = {
    name: 'portainer.licenses',
    url: '/licenses',
    views: {
      'content@': {
        component: 'licensesView',
      },
    },
    data: {
      docs: '/admin/licenses',
      access: AccessHeaders.Admin,
    },
    onEnter: /* @ngInject */ function onEnter($async, $state, Authentication) {
      return $async(async () => {
        if (!Authentication.isPureAdmin()) {
          return $state.go('portainer.home');
        }
      });
    },
  };

  const addLicense = {
    name: 'portainer.licenses.new',
    url: '/licenses/new',
    views: {
      'content@': {
        component: 'licenseCreateView',
      },
    },
  };

  $stateRegistryProvider.register(licenses);
  $stateRegistryProvider.register(addLicense);
}
