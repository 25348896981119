const autoNgTemplateLoaderTemplate1 = require('./accessDatatable.html');

angular.module('portainer.app').component('accessDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'AccessDatatableController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    roles: '<',
    tableKey: '@',
    orderBy: '@',
    removeAction: '<',
    updateAction: '<',
    reverseOrder: '<',
    showWarning: '<',
    isUpdateEnabled: '<',
    showRoles: '<',
    inheritFrom: '<',
  },
});
