import angular from 'angular';

import { r2a } from '@/react-tools/react2angular';
import { withControlledInput } from '@/react-tools/withControlledInput';
import { Note } from '@/react/kubernetes/applications/components/Note';

export const applicationsModule = angular
  .module('portainer.kubernetes.react.components.applications', [])
  .component(
    'note',
    r2a(withControlledInput(Note), [
      'defaultIsOpen',
      'value',
      'onChange',
      'labelClass',
      'inputClass',
      'isRequired',
      'minLength',
      'isExpandable',
    ])
  ).name;
