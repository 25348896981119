import { isExternalStack } from '@/react/docker/stacks/view-models/utils';
import { GitCommitLink } from '@/react/portainer/gitops/GitCommitLink';

import { columnHelper } from './helper';

export const deployedVersion = columnHelper.accessor(
  (item) => {
    if (isExternalStack(item)) {
      return '';
    }

    return item.GitConfig ? item.GitConfig.ConfigHash : item.StackFileVersion;
  },
  {
    header: 'Deployed Version',
    id: 'deployed-version',
    cell: ({ row: { original: item } }) => {
      if (isExternalStack(item)) {
        return <div className="text-center">-</div>;
      }

      if (item.GitConfig) {
        return (
          <div className="text-center">
            <GitCommitLink
              baseURL={item.GitConfig.URL}
              commitHash={item.GitConfig.ConfigHash}
            />
          </div>
        );
      }

      return <div className="text-center">{item.StackFileVersion || '-'}</div>;
    },
    meta: {
      className: '[&>*]:justify-center',
    },
  }
);
