import { ResourceID, ResourceType } from '../types';

export const queryKeys = {
  item: ({
    environmentId,
    resourceId,
    resourceType,
  }: {
    environmentId: number;
    resourceId: ResourceID;
    resourceType: ResourceType;
  }) =>
    [
      'environments',
      environmentId,
      'images',
      resourceType,
      resourceId,
    ] as const,
  list: ({
    environmentId,
    resourceType,
  }: {
    environmentId: number;
    resourceType: ResourceType;
  }) => ['environments', environmentId, 'images', resourceType] as const,
};
