import { useMutation } from 'react-query';

import { queryClient } from '@/react-tools/react-query';

import { ResourceID, ResourceType } from '../types';

import { queryKeys } from './query-keys';
import { getImageStatus } from './useImageStatus';

export function useRefreshImageStatus({
  environmentId,
  resourceId,
  resourceType,
  nodeName,
}: {
  environmentId: number;
  resourceId: ResourceID;
  resourceType: ResourceType;
  nodeName: string;
}) {
  return useMutation(
    () =>
      getImageStatus(environmentId, resourceId, resourceType, nodeName, true),
    {
      onSuccess: (data) => {
        // to reduce api calls, set the query data directly instead of invalidating the query
        // see https://tkdodo.eu/blog/mastering-mutations-in-react-query#direct-updates for more info
        queryClient.setQueryData(
          queryKeys.item({ environmentId, resourceType, resourceId }),
          data
        );
      },
    }
  );
}
