const autoNgTemplateLoaderTemplate1 = require('./template.html');

import angular from 'angular';

import { RegistryRepositoriesController } from './controller.js';

angular.module('portainer.registrymanagement').component('registryRepositoriesView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: RegistryRepositoriesController,
});
