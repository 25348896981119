import { Trash2 } from 'lucide-react';

import { useDeleteGitCredentialMutation } from '@/react/portainer/account/git-credentials/git-credentials.service';
import { GitCredential } from '@/react/portainer/account/git-credentials/types';

import { confirmDestructive } from '@@/modals/confirm';
import { Icon } from '@@/Icon';
import { AddButton, Button } from '@@/buttons';

interface Props {
  selectedItems: GitCredential[];
}

export function GitCredentialsDatatableActions({ selectedItems }: Props) {
  const deleteGitCredentialMutation = useDeleteGitCredentialMutation();

  return (
    <div className="flex gap-2">
      <Button
        disabled={selectedItems.length < 1}
        color="dangerlight"
        onClick={() => onDeleteClick(selectedItems)}
        data-cy="credentials-deleteButton"
      >
        <Icon icon={Trash2} className="vertical-center" />
        Remove
      </Button>

      <AddButton
        to="portainer.account.gitCreateGitCredential"
        data-cy="credentials-addButton"
      >
        Add git credential
      </AddButton>
    </div>
  );

  async function onDeleteClick(selectedItems: GitCredential[]) {
    const confirmed = await confirmDestructive({
      title: 'Confirm action',
      message: `Are you sure you want to remove the selected git ${
        selectedItems.length > 1 ? 'credentials' : 'credential'
      }?`,
    });

    if (!confirmed) {
      return;
    }

    selectedItems.forEach((item) => {
      deleteGitCredentialMutation.mutate(item);
    });
  }
}
