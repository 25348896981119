import { CellContext } from '@tanstack/react-table';
import { AlertCircle, AlertTriangle, HelpCircle, Loader2 } from 'lucide-react';
import clsx from 'clsx';
import sanitize from 'sanitize-html';

import { EnvironmentStatus } from '@/react/portainer/environments/types';
import { notifySuccess } from '@/portainer/services/notifications';
import { PortainerEndpointTypes } from '@/portainer/models/endpoint/models';

import { TooltipWithChildren } from '@@/Tip/TooltipWithChildren';
import { Button } from '@@/buttons';
import { Icon } from '@@/Icon';
import { Tooltip } from '@@/Tip/Tooltip';

import { EnvironmentListItem } from '../types';
import { useUpdateEnvironmentMutation } from '../../queries/useUpdateEnvironmentMutation';

import { columnHelper } from './helper';

export const url = columnHelper.accessor('URL', {
  header: 'URL',
  cell: Cell,
});

function Cell({
  row: { original: environment },
}: CellContext<EnvironmentListItem, string>) {
  const mutation = useUpdateEnvironmentMutation();

  if (
    environment.Type !== PortainerEndpointTypes.EdgeAgentOnDockerEnvironment &&
    environment.Status !== EnvironmentStatus.Provisioning
  ) {
    const opStatus = environment.StatusMessage?.operationStatus;
    const statusDetails =
      opStatus === 'warning'
        ? environment.StatusMessage?.warnings?.join('<br />')
        : environment.StatusMessage?.detail;

    return (
      <div className="inline-flex gap-2 whitespace-nowrap">
        {environment.URL && <div>{environment.URL}</div>}
        {opStatus !== '' && ( // status is in a provisioning, error or warning state
          <div
            className={clsx('vertical-center flex items-center', {
              'text-danger': opStatus === 'error',
              'text-warning': opStatus === 'warning',
              'text-muted': opStatus !== 'error' && opStatus !== 'warning',
            })}
          >
            {opStatus === 'error' && (
              <Icon className="flex-none" icon={AlertCircle} />
            )}
            {opStatus === 'warning' && (
              <Icon className="flex-none" icon={AlertTriangle} />
            )}
            {opStatus === 'processing' && (
              <Icon className="flex-none animate-spin-slow" icon={Loader2} />
            )}
            {environment.StatusMessage?.summary}
            <TooltipWithChildren
              message={
                <div>
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: sanitize(statusDetails ?? ''),
                    }}
                  />
                  {environment.URL && opStatus === 'warning' && (
                    <div className="mt-2 text-right">
                      <Button
                        color="link"
                        className="small !ml-0 p-0"
                        onClick={handleDismissButton}
                      >
                        <span className="text-muted-light">
                          Dismiss error (still visible in logs)
                        </span>
                      </Button>
                    </div>
                  )}
                </div>
              }
              position="bottom"
            >
              <span className="vertical-center text-muted inline-flex whitespace-nowrap text-base">
                <HelpCircle className="lucide" aria-hidden="true" />
              </span>
            </TooltipWithChildren>
          </div>
        )}
      </div>
    );
  }

  if (
    environment.Type === PortainerEndpointTypes.EdgeAgentOnDockerEnvironment
  ) {
    return <>-</>;
  }

  if (environment.Status === EnvironmentStatus.Provisioning) {
    return (
      <div className="inline-flex items-center text-base">
        <Icon icon={Loader2} className="!mr-1 animate-spin-slow" />
        {environment.StatusMessage?.summary}
        {environment.StatusMessage?.detail && (
          <Tooltip message={environment.StatusMessage?.detail} />
        )}
      </div>
    );
  }

  return <>-</>;

  function handleDismissButton() {
    mutation.mutate(
      {
        id: environment.Id,
        payload: {
          IsSetStatusMessage: true,
          StatusMessage: {
            detail: '',
            operation: '',
            operationStatus: '',
            summary: '',
          },
        },
      },
      {
        onSuccess: () => {
          notifySuccess('Success', 'Error dismissed successfully');
        },
      }
    );
  }
}
