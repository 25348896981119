const autoNgTemplateLoaderTemplate1 = require('./logs.html');

angular.module('portainer.kubernetes').component('kubernetesStackLogsViewAngular', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesStackLogsController',
  controllerAs: 'ctrl',
  bindings: {
    $transition$: '<',
  },
});
