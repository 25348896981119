import { XOR } from 'ts-xor';

import { EnvironmentId } from '@/react/portainer/environments/types';

/*
 * This file contains the payloads and responses API types
 */

export enum ChatQueryContext {
  ENVIRONMENT_AWARE = 'environment_aware',
}

export enum ChatQueryOpenAIModel {
  DAVINCI_3 = 'text-davinci-003',
  GPT_35_TURBO = 'gpt-3.5-turbo',
  GPT_4 = 'gpt-4',
}

interface ChatQueryPayloadWithoutContext {
  // Message is the message that will be forwarded to the OpenAI API.
  Message: string;
}

interface ChatQueryPayloadWithContext {
  // Message is the message that will be forwarded to the OpenAI API.
  Message: string;
  // Context is used to build a context that will be added to the prompt sent to OpenAI.
  // 'environment_aware' will create a context based on environment information (EnvironmentID parameter is mandatory).
  Context: ChatQueryContext.ENVIRONMENT_AWARE;
  // EnvironmentID is the ID of the environment for which the chat query is being made
  // it will be used to retrieve context information about the environment and pass it to then OpenAI API.
  // Mandatory when environment_aware context is used.
  EnvironmentID: EnvironmentId;
  // Model is the name of the OpenAI model that will be used to generate the response.
  // Only accepts a list of specific values: text-davinci-003, gpt-3.5-turbo, gpt-4
  // Optional: will default to text-davinci-003 if not provided.
  Model?: ChatQueryOpenAIModel;
}

export type ChatQueryPayload = XOR<
  ChatQueryPayloadWithoutContext,
  ChatQueryPayloadWithContext
>;

export interface ChatQueryResponse {
  // Message is the message that is returned by the OpenAI API.
  message: string;
  // Yaml is the content of the compose / kubernetes manifest file that was generated by the OpenAI API as part of the response.
  yaml?: string;
}
