const autoNgTemplateLoaderTemplate1 = require('./applications-datatable-details.html');

import angular from 'angular';
import controller from './applications-datatable-details.controller';

angular.module('portainer.kubernetes').component('kubernetesApplicationsDatatableDetails', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    configurations: '<',
    isEnvironmentAdmin: '<',
    isSecretsRestricted: '<',
    username: '<',
  },
});
