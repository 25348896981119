import { useMutation } from 'react-query';

import {
  mutationOptions,
  queryClient,
  withError,
  withInvalidate,
} from '@/react-tools/react-query';
import { useEnvironmentId } from '@/react/hooks/useEnvironmentId';
import axios, { parseAxiosError } from '@/portainer/services/axios';

import { ResourceType } from '../types';

import { queryKeys } from './query-keys';

/**
 * Returns a mutation hook for clearing the image status for images in an environment.
 */
export function useClearImagesStatus({
  resourceType,
  swarmId,
}: {
  resourceType: ResourceType;
  swarmId?: string;
}) {
  const environmentId = useEnvironmentId();

  return useMutation(
    () => clearImagesStatus({ environmentId, swarmId, resourceType }),
    mutationOptions(
      withInvalidate(queryClient, [
        queryKeys.list({ environmentId, resourceType }),
      ]),
      withError('Failed to clear image statuses')
    )
  );
}

async function clearImagesStatus({
  environmentId,
  swarmId,
  resourceType,
}: {
  environmentId: number;
  swarmId?: string;
  resourceType: ResourceType;
}) {
  const params = getParams(resourceType, environmentId, swarmId);
  try {
    await axios.post(buildUrl(resourceType, environmentId), undefined, {
      params,
    });
  } catch (error) {
    throw parseAxiosError(error);
  }
}

function buildUrl(resourceType: ResourceType, environmentId: number) {
  if (resourceType === 'stack') {
    return `/stacks/image_status/clear`;
  }
  return `/docker/${environmentId}/${resourceType}s/image_status/clear`;
}

function getParams(
  resourceType: ResourceType,
  environmentId: number,
  swarmId?: string
) {
  if (resourceType === 'stack') {
    return { environmentId, swarmId };
  }
  return undefined;
}
